<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="교육과정 기본정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-if="editable"
              :url="insertUrl"
              :isSubmit="isSave"
              :param="attendeeList"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveAttendee"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div v-if="editable && educationInfo.plantNm" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="사업장" :value="educationInfo.plantNm"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.educationCourseName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육과정" :value="educationInfo.educationCourseName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.machineryClassificationName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육종류1" :value="educationInfo.educationKindCdLargeName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.educationKindCdSmallName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육종류2" :value="educationInfo.educationKindCdSmallName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.educationTypeName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육구분" :value="educationInfo.educationTypeName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.legalEducationFlagName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="법정교육" :value="educationInfo.legalEducationFlagName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.educationTimeName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="교육시간" :value="educationInfo.educationTimeName"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.educationPurpose" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="학습목적" :value="educationInfo.educationPurpose"></c-label-text>
          </div>
          <div v-if="editable && educationInfo.relatedLawsName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="관련법규" :value="educationInfo.relatedLawsName"></c-label-text>
          </div>
        </template>
      </c-card>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-table
            ref="table"
            title="부서 교육대상"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="grid.data"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :hideBottom="true"
            noDataLabel="교육대상자가 없습니다."
            :editable="editable"
            rowKey="attendeesNo"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem('DEPT')" />
                <c-btn v-if="editable && grid.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-table
            ref="table2"
            title="직책 교육대상"
            :columns="grid2.columns"
            :gridHeight="grid2.height"
            :data="grid2.data"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :hideBottom="true"
            noDataLabel="교육대상자가 없습니다."
            :editable="editable"
            rowKey="attendeesNo"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem('POSITION')" />
                <c-btn v-if="editable && grid2.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem2" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-table
            ref="table3"
            title="법규/선임 교육대상"
            :columns="grid3.columns"
            :gridHeight="grid3.height"
            :data="grid3.data"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :hideBottom="true"
            noDataLabel="교육대상자가 없습니다."
            :editable="editable"
            rowKey="attendeesNo"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem('SENIOR')" />
                <c-btn v-if="editable && grid3.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem3" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-table
            ref="table4"
            title="외부인력 교육대상"
            :columns="grid4.columns"
            :gridHeight="grid4.height"
            :data="grid4.data"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :hideBottom="true"
            noDataLabel="교육대상자가 없습니다."
            :editable="editable"
            rowKey="attendeesNo"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem('PERSONAL')" />
                <c-btn v-if="editable && grid4.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem4" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-target-user',
  props: {
    eduCourseId: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      educationInfo: {
        plantNm: '',
        educationCourseName: '',
        educationKindCdLargeName: '',
        educationKindCdSmallName: '',
        educationTypeName: '',
        legalEducationFlagName: '',
        educationPurpose: '',
        educationTimeName: '',
        relatedLawsName: '',
      },
      attendeeList: [],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      eduCycleItems: [],
      grid: {
        columns: [
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:100px',
            label: '부서',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
        height: '650px'
      },
      grid2: {
        columns: [
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:100px',
            label: '직책',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
        height: '650px'
      },
      grid3: {
        columns: [
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            label: '법규/선임',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
        ],
        data: [],
        height: '650px'
      },
      grid4: {
        columns: [
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            label: '소속회사',
            type: 'text',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
        ],
        data: [],
        height: '650px'
      },
      isSave: false,
      editable: true,
      getUrl: '',
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.course.get.url;
      this.getStdUrl = selectConfig.sop.edu.attendee.get.url;
      this.insertUrl = transactionConfig.sop.edu.attendee.insert.url;
      // this.updateUrl = transactionConfig.sop.edu.attendee.update.url;
      // code setting
      this.getDetail();
      this.getSubDetail();
      // list setting
    },
    getDetail() {
      if (this.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 빈값들 filter

          this.educationInfo = this.$_.clone(_result.data);
          this.updateMode = true;
        },);
      }
    },
    getSubDetail() {
      if (this.eduCourseId) {
        this.$http.url = this.$format(this.getStdUrl, this.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.forEach(_result.data, _item => {
            if (_item.attendeesTypeCd === 'EATC00003') {
              this.grid.data.push(_item) // 부서
            } else if (_item.attendeesTypeCd === 'EATC00001') {
              this.grid2.data.push(_item) // 직책
            } else if (_item.attendeesTypeCd === 'EATC00002') {
              this.grid3.data.push(_item) // 선임
            } else {
              this.grid4.data.push(_item) // 개인
            }
          })
          this.updateMode = true;
        },);
      }
    },
    addItem(data) {
      if (data === 'DEPT') this.targetClassCd = '1';
      else if (data === 'POSITION') this.targetClassCd = '2';
      else if (data === 'SENIOR') this.targetClassCd = '3';
      else this.targetClassCd = '4';

      if (this.targetClassCd === '4') {
        this.grid4.data.splice(0, 0, {
          eduCourseId: this.eduCourseId,
          attendeesNo: uuidv4(),
          attendeesTypeCd: 'EATC00004',
          attendeesPositionCd: '',
          attendeesPositionName: '',
          attendeesName: '',
          educationCycle: '',
          editFlag: 'C'
        })
      } else {
        this.popupOptions.title = "교육대상자 검색"; // 교육대상자 검색
        this.popupOptions.param = {
          type: 'multiple',
          targetClassCd: this.targetClassCd,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/edu/result/educationTargetUserPop.vue'}`)
        this.popupOptions.visible = true;
        this.popupOptions.width = '100%';
        this.popupOptions.isFull = false;
        this.popupOptions.closeCallback = this.closeTargetUserPopup;
      }
    },
    closeTargetUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, _item => {
          if (this.targetClassCd === '1') {
            let index = this.$_.findIndex(this.grid.data, {
              attendeesPositionName: _item.attendeesPositionName,
            });
            // 부서 EATC00003
            if (index === -1) {
              this.grid.data.splice(0, 0, {
                eduCourseId: this.eduCourseId,
                attendeesNo: uuidv4(),
                attendeesTypeCd: 'EATC00003',
                attendeesPositionCd: '',
                attendeesPositionName: _item.attendeesPositionName,
                attendeesName: '',
                educationCycle: '',
                editFlag: 'C'
              })
            }
            // 직책 EATC00001
          } else if (this.targetClassCd === '2') {
            let index = this.$_.findIndex(this.grid2.data, {
              attendeesPositionName: _item.attendeesPositionName,
            });
            if (index === -1) {
              this.grid2.data.splice(0, 0, {
                eduCourseId: this.eduCourseId,
                attendeesNo: uuidv4(),
                attendeesTypeCd: 'EATC00001',
                attendeesPositionCd: '',
                attendeesPositionName: _item.attendeesPositionName,
                attendeesName: '',
                educationCycle: '',
                editFlag: 'C'
              })
            }
            // 선임 EATC00002
          } else  {
            let index = this.$_.findIndex(this.grid3.data, {
              attendeesPositionName: _item.attendeesPositionName,
            });
            if (index === -1) {
              this.grid3.data.splice(0, 0, {
                eduCourseId: this.eduCourseId,
                attendeesNo: uuidv4(),
                attendeesTypeCd: 'EATC00002',
                attendeesPositionCd: '',
                attendeesPositionName: _item.attendeesPositionName,
                attendeesName: '',
                educationCycle: '',
                editFlag: 'C'
              })
            }
          }
        })
      }
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, item)
        })
      }
    },
    removeItem2() {
      let selectData = this.$refs['table2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid2.data = this.$_.reject(this.grid2.data, item)
        })
      }
    },
    removeItem3() {
      let selectData = this.$refs['table3'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid3.data = this.$_.reject(this.grid3.data, item)
        })
      }
    },
    removeItem4() {
      let selectData = this.$refs['table4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid4.data = this.$_.reject(this.grid4.data, item)
        })
      }
    },
    saveAttendee() {
      let flag = true;
      if (this.grid.data.length === 0 && this.grid2.data.length === 0 && this.grid3.data.length === 0 && this.grid4.data.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message:
          '부서,직책,선임,개인 대상 중 입력하세요.', // 부서,직책,선임,개인 대상 중 입력하세요.
          type: 'warning', // success / info / warning / error
        });
        flag = false;
        return;
      }
      if (flag) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.attendeeList = [];
            this.attendeeList = (this.$_.concat(this.grid.data, this.grid2.data, this.grid3.data, this.grid4.data));
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('APP_VALID_ERROR');
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>
